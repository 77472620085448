import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Navbar from "./component/Navbar";
import Home from "./component/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import WhatsappIcon from "./Pages/WhatsappIcon";
import About from "./component/About";
import Rooms from "./component/Rooms";
import Services from "./component/Services";
import Contact from "./component/Contact";
// import RoomsDetails from "./component/RoomsDetails";
import Page404 from "./component/Page404";
import Roomdetails from "./component/Roomdetails";
import RoomData from "./component/RoomData";
import Room from "./component/Room";

const App = () => {
  return (
    <BrowserRouter>
              <Navbar />

      <>
        <WhatsappIcon />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/best-resort-kelve" element={<About />} />
          <Route path="/resort-with-rooms-kelve" element={<Rooms />} />
          <Route path="/resort-for-events-palghar" element={<Services />} />
          <Route path="/resort-near-kelve-beach" element={<Contact />} />
          {/* <Route path="/Room" element={<Room/>} /> */}

          {RoomData.map((data,index)=>(
          
           <Route path={data.slug} element={<Roomdetails/>} />
          ))}
          {/* <Route path="/room-particular" element={<Roomdetails />} /> */}
        

          {/* <Route path="/rooms" element={<RoomsDetails />} /> */}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </>
    </BrowserRouter>
  );
};

export default App;
