import React from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { imagePath } from "../images/imagePath";

import Footer from "../component/Footer";
// import RoomData from "./RoomData";

const room1=imagePath.room1;
const room2=imagePath.room2;

const RoomData = [
  {
    imageurl:[room1],
    room: "Super Deluxe Room",
    desc:  "Experience the perfect blend of luxury, comfort, and natural beauty at Raj Resort. Our Super Deluxe Rooms Resort in Kelve offers a serene escape where you can unwind and indulge in a truly relaxing stay. Nestled in the heart of Kelve, our resort provides a peaceful retreat while ensuring modern amenities for a comfortable and memorable vacation.",
    slug: "/super-deluxe-room",
  },
  {
    imageurl:[room2],
    room: "Deluxe Room ",
    desc: "Escape into a world of comfort and relaxation at Raj Resort, a perfect retreat near the stunning Kelwa Beach. Our Deluxe Rooms Resort in Kelve is designed for travelers who seek luxury, tranquility, and a memorable stay surrounded by nature. Whether you’re on a romantic getaway, a family vacation, or a solo trip, our deluxe rooms provide the perfect ambiance for a peaceful and rejuvenating experience.",
    slug: "/deluxe-room",
  },
  {
    imageurl:[room1],
    room: "Standard Room",
    desc:  "Experience a peaceful getaway at Raj Resort, the perfect Standard Rooms Resort in Kelve for travelers seeking relaxation and convenience. Located near the stunning Kelwa Beach, our Standard Rooms provide a blend of comfort, modern amenities, and a serene ambiance. Whether you’re visiting for a weekend retreat or an extended stay, our thoughtfully designed accommodations ensure a rejuvenating experience.",
    slug: "/standard-room",
  },
  {
    imageurl:[room1],
    room: "Family Room",
    desc:  "Enjoy a relaxing and memorable stay at Raj Resort, the ideal Resort in Kelve for Family vacations. Designed to provide maximum comfort and convenience, our Family Rooms offer a spacious and cozy retreat for guests traveling with loved ones. Whether you're planning a weekend getaway or an extended vacation, our well-furnished rooms ensure a home-like experience surrounded by nature’s beauty.",
    slug: "/family-room",
  }
];

const Room = () => {
  const banner = imagePath.bann;

  return (
    <>
      <MetaTags>
        <title>Resort with Rooms in Kelve | Raj Resort</title>
        <meta
          name="description"
          content="Resort in Palghar, Best Resort in Kelve, Resort with Rooms in Kelve, Resort for Events in Palghar, Resort near Kelve Beach"
        />
        <link rel="canonical" href="https://www.raj-resort.in/" />
        <meta httpEquiv="cache-control" content="no-cache" />
        <meta httpEquiv="expires" content="0" />
        <meta httpEquiv="pragma" content="no-cache" />
        <meta property="og:title" content="Resort with Rooms in Kelve | Raj Resort" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.raj-resort.in/" />
        <meta
          property="og:description"
          content="Escape to serenity with our Resort with Rooms in Kelve. Enjoy cozy accommodations, personalized service & unforgettable experiences amidst the beauty."
        />
        <meta
          property="og:image"
          content="https://kinararesort.in/static/media/logo.146c55d2a549f20e2963.png"
        />
      </MetaTags>

      {/* <div className="container-fluid"> */}
        {/* <div className="row  align-items-stretch"> */}
        <div class="page-content">
          <div className="text-center">
            <div
              className="wt-bnr-inr overlay-wraper bg-parallax bg-top-center"
              data-stellar-background-ratio="0.5"
              style={{
                backgroundImage: `linear-gradient(to right, rgba(10, 9, 9, 0.5), rgba(0, 0, 0, 0.5)), url(${banner})`,
                backgroundPosition: "50% -160px",
                backgroundAttachment: "fixed",
              }}
            >
              <div className="overlay-main bg-black opacity-07" />
              <div className="container">
                <div className="wt-bnr-inr-entry">
                  <div className="banner-title-outer">
                    <div className="banner-title-name">
                      <h2 className="">Room</h2>
                    </div>
                  </div>
                  {/* BREADCRUMB ROW */}
                  <div>
                    <ul className="wt-breadcrumb breadcrumb-style-2">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>Room</li>
                    </ul>
                  </div>
                  {/* BREADCRUMB ROW END */}
                </div>
              </div>
</div>
              {/* Room Section */}
              <div className=" mt-4 my-5 mb-5">
                <div className="row justify-content-evenly  align-items-stretch">
                  {RoomData.map((data, index) => (
                    <div key={index} className="col-12 col-md-6 col-lg-4 p-4 h-100">
                      <img
                        src={data.imageurl}
                        className="mt-3"
                        style={{
                          height: "250px",
                          width: "100%",
                          objectFit: "cover",
                          borderRadius: "16px",
                        }}
                        alt={data.room}
                      />
                      <h4 className="mt-2">{data.room}</h4>
                      <p className="Poppins mt-2" style={{ textAlign: "justify" }}>
                        {data.desc.length > 150 ? data.desc.slice(0, 150) + " ...." : data.desc}
                      </p>
                      <Link to={data.slug}> Know More </Link>
                    </div>
                  ))}
                </div>
              </div>
              {/* End Room Section */}
            </div>
          {/* </div>
        </div> */}
        </div>
      

      <Footer />
    </>
  );
};

export default Room;
