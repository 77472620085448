import React from "react";
import { Link, useLocation } from "react-router-dom";
import MetaTags from "react-meta-tags";
import "bootstrap/dist/css/bootstrap.min.css";
// import { PiTelevisionSimpleBold } from "react-icons/pi";
import { IoTvSharp } from "react-icons/io5";
import { FaWifi, FaToilet  } from "react-icons/fa";
import { TbAirConditioning } from "react-icons/tb";
import { imagePath } from "../images/imagePath";
import Footer from "../component/Footer";
import RoomData from "./RoomData";




const Roomdetails = () => {
  
    const search=useLocation();
    const path = search.pathname;
    console.log("path",path);
  
    const finalData=RoomData.find((elem)=> elem.slug==path)
    console.log(finalData);
    console.log(finalData)
    const banner = imagePath.bann;
    const roomwithoutboll = imagePath.room1;
    const room2 = imagePath.bann;



  
  return (
    <>
      <MetaTags>
        <title>{finalData && finalData.title1}</title>
        <meta title={finalData && finalData.title1} />
        <meta
          name="description"
          content={finalData && finalData.desc0}
        />
        <link rel="canonical" href="https://www.raj-resort.in/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content={finalData && finalData.title1} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.raj-resort.in/" />
        <meta
          property="og:description"
          content={finalData && finalData.desc0}
        />
        <meta
          property="og:image"
          content="https://kinararesort.in/static/media/logo.146c55d2a549f20e2963.png"
        />
      </MetaTags>
      
      <div className="text-center">
                <div
                  className="wt-bnr-inr overlay-wraper bg-parallax bg-top-center"
                  data-stellar-background-ratio="0.5"
                  style={{
                    backgroundImage: `linear-gradient(to right, rgba(10, 9, 9, 0.5), rgb(0, 0, 0, 0.5)),url(${banner})`,
                    backgroundPosition: "50% -160px",
                    backgroundAttachment: "fixed",
                  }}
                >
                  <div className="overlay-main bg-black opacity-07" />
                  <div className="container">
                    <div className="wt-bnr-inr-entry">
                      <div className="banner-title-outer">
                        <div className="banner-title-name">
                          <h1 className="text-white">{finalData&&finalData.titletag}</h1>
                        </div>
                      </div>
                      {/* BREADCRUMB ROW */}
                      <div>
                        <ul className="wt-breadcrumb breadcrumb-style-2">
                          <li>
                          <Link to="/">Home</Link>
                          </li>
                          <li>Roomdetails</li>
                        </ul>
                      </div>
                      {/* BREADCRUMB ROW END */}
                    </div>
                  </div>
                </div>
              </div>
    

        <div class="page-content ">
          <div class="row mt-5" style={{padding:'20px'}}>
          <div className="col-lg-6 p-4">
          <img src=
          // {roomwithoutboll} 
          {finalData && finalData.imageUrl} 
          alt="img"
          style={{
            height:'500px',
            width:'100%',
            objectFit:'cover',
            borderRadius:'16px'
          }}/>
        </div>
        <div className="col-lg-6">
          {/* <div className="ri-text"> */}
            
            <div className="section-title">
              <span>
                {/* 6 A/C Rooms */}
                 {finalData && finalData.type}
                </span>
              <h2>
                {/* Super Deluxe Rooms */} 
                {finalData && finalData.room}
                </h2>
            </div>
            <p>
            {/* Experience the perfect blend of luxury, comfort, and natural beauty at Raj Resort. Our Super Deluxe Rooms Resort in Kelve offers a serene escape where you can unwind and indulge in a truly relaxing stay. Nestled in the heart of Kelve, our resort provides a peaceful retreat while ensuring modern amenities for a comfortable and memorable vacation. */}
            {finalData && finalData.desc}
            </p>
            <p>
            {/* Our Super Deluxe Rooms are designed to offer an unparalleled stay experience. Spacious and elegantly furnished, these rooms feature plush king-size beds, premium linens, and a cozy ambiance that instantly makes you feel at home. Step onto your private balcony and immerse yourself in breathtaking views of lush greenery, allowing you to reconnect with nature while enjoying your morning tea or a quiet evening. */}
            {finalData && finalData.desc1}
            </p>
            

            {/* <div style={{ flex:1 ,display:"flex", justifyContent: "center"}}>
            <div className="room-image">
            <img src={ roomwithoutboll } className="" style={{width:'100%',height:'600px'}} alt="img"/>
            <div className="single-img set-bg"
            data-setbg="img/rooms/room-1.jpg"
             style={{ backgroundImage: `url(${roomwithoutboll})` }}
            />
            </div>
           </div> */}
{/* 
            <button className="primary-btn" >
              <Link to="/resort-near-kelve-beach">Make a Reservation</Link>
            </button> */}
          </div>
          <p>
            {/* At Raj Resort, we prioritize your comfort with world-class amenities. Each room is fully air-conditioned and equipped with a flat-screen TV, high-speed Wi-Fi, and a modern attached bathroom with hot and cold water. Whether you're here for a weekend retreat or a long vacation, our attentive staff ensures a hassle-free and delightful experience. */}
            {finalData && finalData.desc2}
            </p>
            <p>
            {/* Beyond the luxurious stay, our Super Deluxe Rooms Resort in Kelve offers various recreational and relaxation options. Take a refreshing dip in our sparkling swimming pool, indulge in a rejuvenating spa session, or savor mouthwatering delicacies at our in-house restaurant. For adventure seekers, nearby beaches and scenic trails provide the perfect opportunity to explore and enjoy thrilling outdoor activities. */}
            {finalData && finalData.desc3}
            </p>
            <p>
            {/* Escape the ordinary and immerse yourself in a world of luxury and tranquility at Raj Resort. Whether you're planning a romantic getaway, a family vacation, or a solo retreat, our Super Deluxe Rooms Resort in Kelve is the perfect destination. Book your stay today and create unforgettable memories in a paradise of comfort and elegance. */}
            {finalData && finalData.desc4}
            </p>
              <div >
                
            <button className="primary-btn" >
              <Link to="/resort-near-kelve-beach">Make a Reservation</Link>
            </button>

              </div>
                <h3 style={{textAlign:'center'}}> 
                  {/* Amenities :  */}
                   {finalData && finalData.title}
                  </h3>
            <div className="ri-features" style={{display:'flex',justifyContent:'space-evenly',padding:"10px",flexWrap:'wrap'}}>
              <div className="ri-info">
                
                <i>
                  {/* <PiTelevisionSimpleBold /> */}
                  <IoTvSharp style={{fontSize: "40px", fontWeight: 'bold', marginRight :"10px"}}/>
                </i>
                 {/* Smart TV  */}
                  {finalData && finalData.span1}
              </div>
              <div className="ri-info">
                <i>
                  <FaWifi style={{fontSize: "40px", fontWeight: 'bold', marginRight :"10px"}} />
                </i>
                 {/* High-Speed Wi-Fi  */}
                  {finalData && finalData.span2}
              </div>
              <div className="ri-info">
                <i>
                  <TbAirConditioning style={{fontSize: "40px", fontWeight: 'bold', marginRight :"10px"}} />
                </i>
                 {/* Air Conditioner */}
                  {finalData && finalData.span3}
              </div>
              <div className="ri-info">
                <i>
                  <FaToilet style={{fontSize: "40px", fontWeight: 'bold', marginRight :"10px"}} />
                </i>
                 {/* Toiletries  */} 
                 {finalData && finalData.span4}
                
                
              </div>
            </div>
        </div>
        
        {/* </div> */}
      </div>
      <Footer/>
    </>
  );
};

export default Roomdetails;
