import { imagePath } from "../images/imagePath"

const room1 = imagePath.room1;
const room2= imagePath.room2;

const RoomData =[
    {
        type : "6 A/C Rooms",
        room : "Super Deluxe Room",
        slug : "/super-deluxe-rooms-resort-kelve",
        desc : "Experience the perfect blend of luxury, comfort, and natural beauty at Raj Resort. Our Super Deluxe Rooms Resort in Kelve offers a serene escape where you can unwind and indulge in a truly relaxing stay. Nestled in the heart of Kelve, our resort provides a peaceful retreat while ensuring modern amenities for a comfortable and memorable vacation.",
        desc1: "Our Super Deluxe Rooms are designed to offer an unparalleled stay experience. Spacious and elegantly furnished, these rooms feature plush king-size beds, premium linens, and a cozy ambiance that instantly makes you feel at home. Step onto your private balcony and immerse yourself in breathtaking views of lush greenery, allowing you to reconnect with nature while enjoying your morning tea or a quiet evening.",
        desc2 :"At Raj Resort, we prioritize your comfort with world-class amenities. Each room is fully air-conditioned and equipped with a flat-screen TV, high-speed Wi-Fi, and a modern attached bathroom with hot and cold water. Whether you're here for a weekend retreat or a long vacation, our attentive staff ensures a hassle-free and delightful experience.",
        desc3 :" Beyond the luxurious stay, our Super Deluxe Rooms Resort in Kelve offers various recreational and relaxation options. Take a refreshing dip in our sparkling swimming pool, indulge in a rejuvenating spa session, or savor mouthwatering delicacies at our in-house restaurant. For adventure seekers, nearby beaches and scenic trails provide the perfect opportunity to explore and enjoy thrilling outdoor activities.",
        desc4 :"  Escape the ordinary and immerse yourself in a world of luxury and tranquility at Raj Resort. Whether you're planning a romantic getaway, a family vacation, or a solo retreat, our Super Deluxe Rooms Resort in Kelve is the perfect destination. Book your stay today and create unforgettable memories in a paradise of comfort and elegance.",
        imageUrl : [room1],
        title : "Amenities : ",
        span1 : "Smart TV ",
        span2 : "High-Speed Wi-Fi ",
        span3 : "Air Conditioner",
        span4 : "Toiletries",
        title1:"Super Dulex Rooms Resort in Kelve | Raj Resort",
        desc0:"Discover the best Super Dulex Rooms Resort in Kelve for a luxurious and relaxing stay. Enjoy premium amenities, serene surroundings, & top-notch hospitality. ",
        titletag:'Super Dulex Rooms Resort in Kelve '
    },

    {
        type :"2 A/C room",
        room : "Deluxe Room",
        slug : "/dulex-rooms-resort-kelve",
        desc : "Escape into a world of comfort and relaxation at Raj Resort, a perfect retreat near the stunning Kelwa Beach. Our Deluxe Rooms Resort in Kelve is designed for travelers who seek luxury, tranquility, and a memorable stay surrounded by nature. Whether you’re on a romantic getaway, a family vacation, or a solo trip, our deluxe rooms provide the perfect ambiance for a peaceful and rejuvenating experience.",
        desc1: "Each Deluxe Room at Raj Resort is thoughtfully designed to blend elegance with modern comfort. Spacious and beautifully furnished, these rooms feature plush bedding, soothing décor, and a private balcony where you can admire breathtaking views of lush greenery. Wake up to the refreshing sea breeze and unwind in a setting that instantly makes you feel at home. The warm and inviting atmosphere ensures a restful stay, allowing you to relax and recharge.",
        desc2 : "To enhance your experience, we offer world-class amenities, including fully air-conditioned rooms, a flat-screen TV with entertainment options, high-speed Wi-Fi, and an attached bathroom with 24/7 hot and cold water. Room service is available to cater to your needs, ensuring a hassle-free and enjoyable stay.",
        desc3 : "Beyond the cozy accommodations, our Deluxe Rooms Resort in Kelve provides a variety of recreational and relaxation options. Take a refreshing dip in our swimming pool, indulge in mouthwatering delicacies at our in-house restaurant, or enjoy a rejuvenating spa session. For adventure seekers, the nearby Kelwa Beach and nature trails offer exciting outdoor activities, making your stay even more thrilling.",
        desc4 : "At Raj Resort, we strive to create unforgettable memories for our guests. Whether you seek relaxation, adventure, or a mix of both, our Deluxe Rooms Resort in Kelve is the ideal destination. Book your stay today and experience the perfect blend of comfort, nature, and warm hospitality. We look forward to welcoming you!",
        imageUrl : [room2],
        title :"Amenities : ",
        span1 : "Smart TV ",
        span2 : "High-Speed Wi-Fi ",
        span3 : "Air Conditioner",
        span4 : "Toiletries",
        title1:"Dulex Rooms Resort in Kelve | Raj Resort",
        desc0 :" Experience a comfortable and luxurious stay at Dulex Rooms Resort in Kelve. Enjoy top amenities, peaceful surroundings, and exceptional hospitality. ",
        titletag:'Dulex Rooms Resort in Kelve '


    },

    {
        type :"2 a/c rooms",
        room : "Standard Room",
        slug : "/standard-rooms-resort-kelve",
        desc : "Experience a peaceful getaway at Raj Resort, the perfect Standard Rooms Resort in Kelve for travelers seeking relaxation and convenience. Located near the stunning Kelwa Beach, our Standard Rooms provide a blend of comfort, modern amenities, and a serene ambiance. Whether you’re visiting for a weekend retreat or an extended stay, our thoughtfully designed accommodations ensure a rejuvenating experience.",
        desc1: "Each Standard Room is well-furnished with cozy bedding, elegant interiors, and soothing décor, creating a warm and inviting atmosphere. The spacious layout allows you to unwind and enjoy your stay in complete comfort. Wake up to the fresh coastal air, admire the natural beauty around you, and embrace the tranquility that makes Raj Resort a perfect escape from the city's hustle.",
        desc2 : "To enhance your comfort, our Standard Rooms Resort in Kelve offers essential amenities, including air conditioning, a flat-screen TV with entertainment options, high-speed Wi-Fi, and an attached bathroom with 24/7 hot and cold water. We ensure a seamless experience with round-the-clock room service, making your stay hassle-free and enjoyable.",
        desc3 : "Beyond your room, Raj Resort provides a variety of facilities designed to make your stay special. Take a dip in our refreshing swimming pool, savor delicious meals at our in-house restaurant, or relax in our peaceful surroundings. The nearby Kelwa Beach offers breathtaking views, peaceful walks, and exciting outdoor activities for nature lovers and adventure seekers alike.",
        desc4 : "At Raj Resort, we are committed to offering an unforgettable experience to every guest. Whether you're here for a short escape or a long vacation, our Standard Rooms Resort in Kelve ensures the perfect balance of relaxation and modern living. Book your stay today and experience the best of comfort, nature, and warm hospitality. We look forward to welcoming you!",
        imageUrl : [room1],
        title :"Amenities : ",
        span1 : "Smart TV ",
        span2 : "High-Speed Wi-Fi ",
        span3 : "Air Conditioner",
        span4 : "Toiletries",
        title1:"Standard Rooms Resort in Kelve | Raj Resort ",
        desc0:"Enjoy a comfortable and budget-friendly stay at a Standard Rooms Resort in Kelve. Experience peaceful surroundings, great amenities, and warm hospitality.",
        titletag:'Standard Rooms Resort in Kelve '

    },

    {
        type :"2 A/C room (8 to 10 Guest)",
        room : "Family Room",
        slug : "/resort-kelve-for-family",
        desc : "Enjoy a relaxing and memorable stay at Raj Resort, the ideal Resort in Kelve for Family vacations. Designed to provide maximum comfort and convenience, our Family Rooms offer a spacious and cozy retreat for guests traveling with loved ones. Whether you're planning a weekend getaway or an extended vacation, our well-furnished rooms ensure a home-like experience surrounded by nature’s beauty.",
        desc1: "Each Family Room is thoughtfully designed to accommodate large groups, making it perfect for families and friends. With the capacity to host over 10 guests, these rooms feature plush bedding, modern furnishings, and warm interiors that create a welcoming atmosphere. The private balcony offers breathtaking views of the lush landscape, allowing you to relax and enjoy peaceful moments with your family.",
        desc2 : "To make your stay truly comfortable, our rooms are equipped with top-notch amenities, including air conditioning, a flat-screen TV, high-speed Wi-Fi, and an attached bathroom with round-the-clock hot and cold water. Our dedicated staff ensures that all your needs are met, providing excellent service so you can focus on making wonderful memories with your loved ones.",
        desc3 : "Beyond the luxurious accommodations, our Resort in Kelve for Family offers a range of exciting activities and facilities. Enjoy a fun-filled day at our sparkling swimming pool, relish delicious multi-cuisine dishes at our in-house restaurant, or unwind with a relaxing spa session. Kids and adults alike can explore the nearby beaches, scenic nature trails, and outdoor adventure spots for an unforgettable experience.",
        desc4 : "At Raj Resort, we believe in making every family vacation special. Whether you’re here for relaxation or adventure, our Resort in Kelve for Family provides the perfect setting to bond with your loved ones. Book your stay today and create cherished moments in a place where comfort meets nature. We look forward to welcoming you!",
        imageUrl : [room1],
        title :"Amenities : ",
        span1 : "Smart TV ",
        span2 : "High-Speed Wi-Fi ",
        span3 : "Air Conditioner",
        span4 : "Toiletries",
        title1:"Resort in Kelve for Family | Raj Resort  ",
        desc0:"Plan a perfect family getaway at the best resort in Kelve. Enjoy spacious accommodations, family-friendly amenities, and a peaceful environment.",
        titletag:'Resort in Kelve for Family '

},


]

export default RoomData;